<template>
  <sdCards>
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">Profile Details</sdHeading>
        <span>Set Up Your Personal Information</span>
      </div>
    </template>
    <BasicFormWrapper>
      <a-form
        layout="horizontal"
        :model="formState"
        :rules="rules"
        @submit="onPersonalDetailsSubmit"
      >
        <a-row :gutter="25">
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-Item label="First Name">
              <a-input
                required
                name="first_name"
                placeholder="Enter first name"
                v-model:value="formState.first_name"
              />
            </a-form-Item>
            <a-form-Item label="Last Name">
              <a-input
                required
                placeholder="Enter last name"
                v-model:value="formState.last_name"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Email">
              <a-input
                disabled
                type="email"
                placeholder="Enter email"
                v-model:value="formState.email"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="setting-card-title">
          <sdHeading as="h4">Password Details</sdHeading>
          <span>Set Up Your Security Information</span>
        </div>
        <a-row :gutter="25">
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Password">
              <a-input
                type="password"
                placeholder="Enter password"
                v-model:value="formState.password"
              />
            </a-form-item>
          </a-col>
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item label="Confirm Password">
              <a-input
                :required="formState.password.length > 0"
                placeholder="Enter confirm password"
                v-model:value="confirm_password"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="setting-form-actions text-center">
          <sdButton size="default" htmlType="submit" type="primary">
            Update
          </sdButton>
        </div>
      </a-form>
    </BasicFormWrapper>
  </sdCards>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { BasicFormWrapper } from "../../../styled";
import Notif from "@/services/Notification";

const Profile = defineComponent({
  name: "Profile",
  components: { BasicFormWrapper },
  data() {
    return {
      formState: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      rules: {},
      confirm_password: "",
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.authUser;
    },
  },
  beforeMount() {
    const { first_name = "", last_name = "", email = "" } = this.user;
    this.formState = {
      ...this.formState,
      first_name,
      last_name,
      email,
    };
  },
  methods: {
    ...mapActions(["updateAdminProfile"]),
    handleCancel(e) {
      e.preventDefault();
    },
    async onPersonalDetailsSubmit(e) {
      e.preventDefault();
      if (this.formState.password != this.confirm_password) {
        Notif.show("Please enter valid password", {
          type: "info",
          title: "Password issue!",
        });
        return null;
      }
      try {
        await this.updateAdminProfile(this.formState);
      } catch (error) {
        if (error?.message) {
          Notif.show(error?.message, { type: "error", title: "Error" });
        }
      }
    },
  },
  created() {
    const rules = {};
    for (const [key] of Object.entries(this.formState)) {
      rules[key] = [
        {
          required: true,
          message: `Please enter ${key.replace("_", " ")}`,
          trigger: "blur",
        },
      ];
    }
    this.rules = rules;
  },
});

export default Profile;
</script>
